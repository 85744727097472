.sidemenu_wrapper {
	position: sticky;
	// top: 1.5rem;
	top: 0;
	height: 100vh;
	padding: $padding_inline;
	padding-inline-end: 0;
	width: 4rem;
	overflow: hidden;
	overflow-y: scroll;

	&.hide_sidebar {
		display: none;
	}

	// .label {
	// 	opacity: 0;
	// }

	&::-webkit-scrollbar {
		display: none !important;
	}

	.nav_links {
		.toggler {
			display: flex;
			flex-direction: column;
			color: #fff;
			margin-bottom: 0.3rem;

			.toggler_header {
				padding: 0.8rem;
				display: flex;
				align-items: center;
				gap: 1rem;
				color: #fff;
				border-radius: $btns_border_radius;
				cursor: pointer;
				transition: all ease-in-out 0.25s;
				overflow: hidden;
				text-wrap: nowrap;

				.icon {
					min-width: 1.25rem;
					width: 1.25rem;
					height: 1.25rem;

					&.custom-style {
						max-width: 1.25rem;
						max-height: 1.25rem;
					}

					&.custom-style2 {
						filter: invert(1);
					}
				}

				.label {
					font-size: 0.9rem;
				}

				.chevron {
					margin-inline-start: auto;
				}

				&.active {
					background-color: #fff;
					color: #000;

					.icon {
						&.custom-style {
							filter: invert(1);
						}

						&.custom-style2 {
							filter: invert(0);
						}
					}

					svg {
						path {
							fill: #000;
						}

						stroke: #000;
					}
				}

				&.just_expanded_header,
				&:hover:not(.active) {
					background-color: rgba(255, 255, 255, 0.2);
					backdrop-filter: blur(20px);
				}
			}

			.collapsed_items {
				display: flex;
				flex-direction: column;
				height: 0;
				overflow: hidden;

				.inner {
					backdrop-filter: blur(20px);
					border-radius: 1.5rem;
					margin-top: 0.3rem;
					padding: 0.4rem;
					background: rgba(255, 255, 255, 0.2);

					.item {
						transition: all ease-in-out 0.25s;
						border-radius: $btns_border_radius;

						&:not(:last-child) {
							margin-bottom: 0.2rem;
						}

						a {
							display: flex;
							align-items: center;
							gap: 0.5rem;
							padding: 0.75rem;
							width: 100%;
							height: 100%;
							text-wrap: nowrap;

							.icon {
								position: relative;
								width: 1rem;
								min-width: 1rem;
								height: 1rem;

								&.custom-style {
									max-width: 1.25rem;
									max-height: 1.25rem;
								}

								&.custom-style2 {
									filter: invert(1);
								}

								svg {
									width: 100%;
									height: 100%;

									path {
										transition: all ease-in-out 0.15s;
									}
								}
							}

							.label {
								font-size: 0.85rem;
							}
						}

						&:hover {
							background-color: rgba(255, 255, 255, 0.1);
						}

						&.active {
							background-color: rgba(255, 255, 255, 0.85);
							// background-color: #fff;
							color: #000;

							.icon {
								&.custom-style {
									filter: invert(1);
								}

								&.custom-style2 {
									filter: invert(0);
								}
							}

							svg {
								path {
									fill: #000;
								}

								stroke: #000;
							}
						}
					}
				}
			}
		}
	}

	.lang {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 0.25rem;
		color: white;
		padding: 0.8rem;
	}

	.lang-name {
		display: flex;
	}	

	.logo_expand_container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 3rem;
		margin-top: 0.85rem;
		gap: 1rem;
		// height: 5rem;

		.logo_container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			padding-inline-start: 0.65rem;

			h4 {
				font-size: 1.25rem !important;
				color: #fff;
			}

			.flag_label {
				position: relative;
				width: 2rem;
				min-width: 2rem;

				svg {
					width: 100%;
				}
			}

			.pa_label {
				position: relative;
				width: 3.5rem;
				min-width: 3.5rem;

				svg {
					width: 100%;

					path {
						fill: #fff;
					}
				}
			}

			.expand {
				display: flex;
				width: 1.85rem;
				height: 1.85rem;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.1);
				cursor: pointer;

				svg {
					transform: 0.25s all;
				}
			}
		}

		.expand_btn {
			position: relative;
			width: 2.5rem;
			// height: 1.25rem;
			height: 0.95rem;
			cursor: pointer;

			svg {
				position: absolute;
				right: 0;
			}

			.collapsed_icon {
				opacity: 1;
			}

			.expanded_icon {
				opacity: 0;
			}

			svg {
				transition: all ease-in-out 0.25s;
				transform: rotate(0);
			}

			&.keep_expanded {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.sidemenu_wrapper {
		// display: none;

		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: #000;
		padding: 1rem;
		padding-top: 0;
		transition: 0.5s all;
		transform: translateX(100%);
		overflow-y: scroll;
		overflow: unset;
		z-index: 10;

		//Mobile Only
		&.open {
			transform: translateX(0);
			display: block;
		}
		&.close {
			transform: translateX(100%);
		}

		.logo_expand_container {
			height: unset;
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1.5rem;
			margin-top: 0.85rem;
			gap: 1rem;
			position: sticky;
			top: 0;
			background-color: #000;
			padding-top: 1rem;
			z-index: 10;

			.expand_btn {
				margin-inline-end: 0.8rem;
				&.keep_expanded {
					svg {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
}