@import './fonts.scss';
@import './variables.scss';
@import './buttons/button.scss';
@import './formInputs/fieldWrapper.scss';
@import './formInputs/radioWrapper.scss';
@import './loaders/InitialLoader.scss';
@import './loaders/Skeleton.scss';
@import './loaders/BasicInfoSkeleton.scss';
@import './layout/SideMenu.scss';
@import './layout/Layout.scss';
@import './layout/AdminPanelHeader.scss';
@import './layout/Breadcrumbs.scss';
@import '../styles/formInputs/generalFormStyles.scss';
@import '../styles/layout/Toastify.scss';
@import '../styles/common/TableContainer.scss';
@import '../styles/common/ModalContainer.scss';
@import '../styles/common/ListHeader.scss';
@import '../styles/common/SearchInputField.scss';
@import '../styles/common/InputFieldsGeneralStyles.scss';
@import '../styles/common/SectionHeader.scss';
@import '../styles/common/DragDropWrapper.scss';
@import '../styles/common/CommonHighlights.scss';
@import '../styles/common/FormSteps.scss';
@import '../modules/distributors/styles/DistributorInfo.scss';
@import '../modules/steps/styles/entity.scss';
@import '../styles/common/Pagination.scss';
@import '../modules/dashboard/styles/DashboardData.scss';
@import '../modules/dashboard/styles/DurationTabs.scss';
@import '../modules/dashboard/styles/TopData.scss';
@import '../modules/dashboard/styles/LandingPage.scss';
@import '../styles/common/Tabs.scss';
@import '../styles/common/ContactInfo.scss';
@import '../styles/common/ErrorCard.scss';
@import '../styles/common/SuccessCard.scss';

//Login
@import '../modules/auth/styles/login.scss';
@import '../modules/auth/styles/textContainer.scss';

//Products
@import '../modules/products/styles/ProductDetails.scss';

//FindDeal
@import '../modules/findDeal/styles/SearchResults.scss';
@import '../modules/findDeal/styles/FilterModal.scss';
@import '../modules/findDeal/styles/PersonalInfoForm.scss';

//Notifications
@import '../modules/notifications/styles/Notifications.scss';

//Applications
@import '../modules/applications/styles/Comments.scss';
//applications
@import '../modules/applications/styles/SingleEntity.scss';
@import '../modules/applications/styles/SingleStep.scss';

:root {
  --saans-trial-regular: 'PlusJakartaSans-Light', sans-serif;
  --saans-trial-medium: 'PlusJakartaSans-Medium', sans-serif;
  --saans-trial-bold: 'PlusJakartaSans-Bold', sans-serif;
}

h1,
h2,
h3,
h5,
strong {
  font-family: var(--saans_trial_regular);
}

p,
h6 {
  font-family: var(--saans_trial_regular);
}

.clickable {
  cursor: pointer;
}

.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.normal-cursor {
  cursor: default !important;
  pointer-events: none;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

body.no-scroll {
  position: fixed;
  overflow: hidden;
  height: 100%;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  // font-family: var(--saans_trial_regular);
  font-family: var(--saans_trial_medium);
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.1;
}

p + p {
  margin: 0;
}

p,
a,
ul {
  margin: 0;
  padding: 0;
  // font-size: 1rem;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
strong {
  font-family: var(--saans_trial_medium);
  // font-family: var(--saans_trial_bold);
}

p {
  // font-family: var(--saans_trial_regular);
  font-family: var(--saans_trial_medium);
}

body,
input,
textarea,
select {
  font-family: var(--saans_trial_medium), 'Arial';
  // font-size: 0.85rem;
  font-weight: 400;
  line-height: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

h1 {
  font-size: 6rem;
  line-height: 100%;
  text-transform: uppercase;
}

h2 {
  font-size: 4rem;
  line-height: 100%;
  text-transform: uppercase;
}

h3 {
  font-size: 3rem;
  line-height: 100%;
  text-transform: uppercase;
}

h4 {
  // font-size: 2.5rem;
  // font-size: 2rem;
  // font-size: 1.75rem;
  font-size: 1.5rem;
  line-height: 100%;
  // text-transform: uppercase;
  text-transform: capitalize;
}

h5 {
  font-size: 0.75rem;
  line-height: 100%;
  // text-transform: uppercase;
  text-transform: capitalize;
}

h6 {
  font-size: 1rem;
  line-height: 100%;
}

p {
  font-size: 1rem;
  line-height: 140%;
}

.capitalize {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}



.not-found-container {
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-title {
  font-size: 1.5rem;
  color: #000;
  margin: 0;
}

.not-found-text {
  font-size: 1.25rem;
  color: #343a40;
  margin: 20px 0;
}

@media (max-width: 600px) {
  .MuiStack-root {
    flex-direction: column !important;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1500px) {
  html {
    // font-size: 18px;
  }
}
