.field_wrapper_container {
  // padding: 2rem 0;
  padding-bottom: 2.5rem;
  position: relative;
  width: 100%;

  .error {
    position: absolute;
    bottom: 1.5rem;
    left: $padding_inline;
    color: red;
    font-size: 0.85rem;

    &.success {
      color: rgb(19 188 19);
      bottom: 1.25rem;
    }

    &.check {
      bottom: 1rem;
    }
  }

  &:last-of-type {
    // padding-bottom: 0;
  }

  &.hidden_inputs {
    display: none;
  }

  &.no-padding {
    padding-bottom: 0;
  }

  &.un_capitalized {
    .header {
      .title {
        text-transform: unset;
      }
    }
  }

  &.not_allowed_cursor {
    .input_wrapper,
    .rmdp-container input,
    .date_range_input input {
      cursor: not-allowed;
    }

    .react-select__control,
    .rmdp-container input,
    .date_range_input,
    .date_range_input input {
      background-color: #f8f8f8;
    }

    .react-select__indicators {
      display: none;
    }
  }

  .header {
    margin-bottom: 1rem;

    &.radio-header {
      margin-bottom: 1rem;
    }

    .title {
      // font-size: 1.2rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      svg {
        margin-left: 0.5rem;
        cursor: pointer;
      }

      // .desc {
      //   display: inline;
      // }
      svg:hover ~ .tooltip-box {
        display: flex;
        animation: fade 0.5s;
      }
    }

    .desc {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      width: fit-content;

      svg {
        cursor: pointer;
      }

      svg:hover ~ .tooltip-box {
        display: flex;
        animation: fade 0.5s;
      }
    }

    .tooltip-box {
      position: absolute;
      left: calc(100% + 1rem);
      background-color: #fff;
      border-radius: 8px;
      // padding: $padding_inline 1rem;
      padding: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      display: none;
      flex-direction: column;
      // width: 25rem;
      width: 15rem;
      z-index: 7;
      border: $border;
      gap: 1rem;
      box-shadow:
        0 3px 6px -4px rgba(0, 0, 0, 0.1),
        0 6px 16px rgba(0, 0, 0, 0.1),
        0 9px 28px 8px rgba(0, 0, 0, 0.1);

      .desc {
        text-transform: none;
        font-size: 0.7rem;

        ul {
          list-style-type: disc;
          padding-inline-start: 1rem;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -1rem;
        border: 0.5rem solid transparent;
        border-right-color: #fff;
      }
    }
  }

  .input_wrapper {
    position: relative;

    .error {
      position: absolute;
      bottom: -$padding_inline;
      left: $padding_inline;
      color: red;
      font-size: 0.85rem;
    }

    .error-icon {
      position: absolute;
      right: 1.5rem;
      transform: translateY(-50%);
      top: 50%;
      color: red;
    }

    .field {
      position: relative;

      .fixed-place-holder {
        position: absolute;
        right: 1rem;
        padding-inline-start: 0.8rem;
        border-inline-start: 1px solid rgba(0, 0, 0, 0.4);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.5;
        height: 70%;
        font-size: 0.8rem;
      }

      input {
        flex: 1;
        width: 100%;
        // padding: $padding_inline_inputs;
        padding: 1rem $padding_inline_inputs;
        border-radius: $btns_border_radius;
        outline: 0;
        border: $border;
        transition: 0.2s all;
        font-size: 0.9rem;

        &::placeholder {
          font-size: 0.85rem;
        }

        &:focus {
          border: $border;
          outline: 0;
        }

        &:focus-visible {
          border: $border;
          outline: 0;
        }

        &.input-error {
          border-color: red;
          background-color: #f7e7e9;

          &:focus {
            border-color: red;
          }

          &:focus-visible {
            border-color: red;
          }
        }

        &::after {
          visibility: visible !important;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
          -webkit-appearance: none;
          margin: 0;
        }
      }

      textarea {
        border: none;
        overflow: auto;
        resize: none;
        // IMPORTANT TO PREVENT RESIZE ITS WIDTH
        width: 100% !important;
        z-index: 1;
        position: relative;
        // background-color: transparent;
        font-size: 1rem;
        line-height: 1.2;
        padding: $padding_inline_inputs;
        // border-radius: $btns_border_radius;
        border-radius: $border_radius;
        outline: 0;
        border: $border;
        transition: 0.2s all;
        font-size: 0.9rem;

        &:focus-visible {
          outline: none !important;
          position: relative;
        }

        &.input-error {
          border-color: red;

          &:focus {
            border-color: red;
          }

          &:focus-visible {
            border-color: red;
          }
        }
      }

      .max_length_hint {
        position: absolute;
        bottom: -$padding_inline;
        right: 0;
        font-size: 0.8rem;
        color: rgba($color: #000000, $alpha: 0.8);
      }

      .tick {
        position: absolute;
        right: calc($padding_inline_inputs + 2rem);
        top: 50%;
        transform: translateY(-50%);

        svg {
          path {
            fill: $green_color;
          }
        }

        &.showPassword {
          right: 2rem;

          svg {
            path {
              fill: #b0b0b0;
            }
          }
        }
      }

      .PhoneInput {
        overflow: hidden;
        font-family: 'public-font' !important;

        .PhoneInputCountry {
          position: absolute !important;
          width: 5rem;
          height: 100%;
          border-top-left-radius: $btns_border_radius;
          border-bottom-left-radius: $btns_border_radius;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          background-color: #f6f6f6;
          border: $border;

          .PhoneInputCountrySelectArrow {
            opacity: 1;
            width: 0.35rem;
            height: 0.35rem;
            color: #000 !important;
          }

          .PhoneInputCountryIcon {
            border: none !important;
            box-shadow: none !important;
          }
        }

        input {
          padding-inline-start: 6rem;
          overflow: hidden;
          height: 100%;
        }
      }

      .phone-wrapper {
        position: relative;

        .phone-error {
          position: absolute;
          color: red;
          font-size: 0.85rem;
          bottom: -$padding_inline;
        }
      }

      .select-drop-down {
        &.disabled {
          opacity: 0.4;
        }

        &.dnd-select {
          padding-bottom: 0;

          .react-select__control {
            .react-select__value-container {
              flex-direction: row-reverse;
            }
          }
        }

        &.searchable_select {
          .react-select__control {
            &.react-select__control--is-focused {
              &.react-select__control--menu-is-open {
                .react-select__placeholder {
                  display: none;
                }

                .react-select__value-container {
                  &.react-select__value-container--has-value {
                    .react-select__single-value {
                      display: none;
                    }
                  }
                }

                .react-select__input-container {
                }
              }
            }
          }
        }
      }
    }
  }

  .add-time-button {
    width: 100%;
    padding: 1.5rem 0;
    border: $border;
    border-radius: 12px;
    font-size: 0.9rem;
    background-color: #e9e8eb;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.date_range_input {
  width: 100%;
  border: $border;
  padding: 0.95rem $padding_inline;
  border-radius: $btns_border_radius;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  .val {
    font-size: 0.85rem;

    .placeholder {
      opacity: 0.5;
    }

    // white-space: pre;
    .dates {
      display: flex;
      align-items: center;
      gap: 3rem;
    }

    // font-weight: 500;

    // &.last {
    //   font-size: 1rem;
    // }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer !important;
  }

  &.error-date {
    border: 1px solid red;
    background-color: #f7e7e9;
  }

  .error {
    position: absolute;
    bottom: -$padding_inline;
    left: $padding_inline;
    color: red;
    font-size: 0.85rem;
  }
}

.rdrDateRangeWrapper {
  position: absolute;
  z-index: 4;
  border-radius: 12px;
  overflow: hidden;
  border: $border;
  // margin-top: -0.5rem;

  .rdrDefinedRangesWrapper {
    display: none;
  }

  input {
    border: 0;
  }
}

.rmdp-container {
  width: 100%;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  border-radius: 2.25rem;

  .rmdp-input {
    padding: 1rem 1.5rem !important;
    outline: 0;
    transition: 0.2s all;
    font-size: 0.9rem !important;
    width: 100%;
    border: unset;
    background-color: transparent;
    font-size: 0;
    box-shadow: unset;
    height: unset !important;

    &::placeholder {
      font-size: 0.85rem;
    }
  }

  .rmdp-wrapper {
    .rmdp-right,
    .rmdp-left {
      background-color: #e1e7f0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;

      i {
        margin: 0;
        border-color: #000;
        opacity: 0.7;
      }
    }

    .rmdp-week-day {
      color: #000;
      opacity: 0.8;
    }

    .rmdp-day span {
      font-weight: 500;
    }
  }

  .rmdp-input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.quill.ql-error {
  .ql-toolbar {
    border-bottom: 1px solid red;
  }

  .ql-container {
    border-left: 1px solid red;
    border-right: 1px solid red;
    border-bottom: 1px solid red;
  }
}

.country-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.MuiRadioGroup-root {
  width: fit-content;
  display: flex;
  flex-direction: row;

  label {
    text-transform: capitalize;

    span {
      font-size: 0.85rem;
    }
  }
}

.input-error {
  border-color: red !important;
  border: 1px solid red !important;
  border-radius: 2.25rem;
  background-color: #f7e7e9 !important;
  background: #f7e7e9 !important;

  .react-select__control {
    background-color: #f7e7e9 !important;
  }
}

.field-array-container {
  background: #fcfcfc;
  border-radius: 12px;
  margin-bottom: 2rem;
  // overflow: hidden;
  display: flex;
  flex-direction: column;

  &.noMarginBottom {
    margin-bottom: 0;
  }

  &.noPadding {
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }

  &.remove-margin {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: 0.7;
    // pointer-events: none;
  }

  .header-container {
    padding: 1.5rem;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    cursor: pointer;
    position: relative;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    overflow: hidden;
    transition: 0.25s all;

    p {
      font-weight: 500;
      font-size: 0.85rem;
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.expand {
      border-radius: 12px;
    }
  }

  .MuiPaper-root {
    border-radius: 12px;
    box-shadow: none;
  }

  .MuiAccordionDetails-root {
    padding: 2.5rem 1.5rem 1.5rem;
    background: #fcfcfc;
    padding-bottom: 1rem;
    border-radius: 12px;

    input {
      width: 100%;
    }
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-radius: 12px;
    box-shadow: none;
  }

  .field-wrapper-container {
    padding-inline: 0.5rem;

    .select-type {
      display: flex;
      flex-wrap: wrap;
      gap: 5rem;

      .type {
        position: relative;
        flex: 0.35;

        .error {
          width: 19rem !important;
        }
      }

      .counter {
        display: flex;
        border: $border;
        // // //border-radius: $gray_bg;
        overflow: hidden;
        background-color: #fff;
        width: fit-content;
        margin-top: 1.5rem;

        div {
          height: 3.25rem;
          font-weight: 700;
          font-size: 0.875rem;
          transition: 0.25s all;
          min-width: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;

          &.sign {
            font-size: 1rem;
            background-color: $gray_bg;
            cursor: pointer;
            user-select: none;

            &.disabled {
              cursor: unset;

              &:hover {
                color: #000;
                background-color: $gray_bg;
              }
            }

            &:hover {
              color: #fff;
              background-color: $gray_bg;
            }
          }

          input {
            padding-inline: 0;
            font-family: 'public-font';
            font-weight: 600;
            width: 4rem;
            text-align: center;
          }
        }
      }
    }
  }

  &.no-margin {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .date_range_input .val .dates {
    gap: 1rem;
  }
  .field-array-container {
    .MuiAccordionDetails-root {
      padding: 2.5rem 0.5rem 0rem;
    }
  }
}
