.customer-details-wrapper {
  position: relative;
  &.open {
    min-height: 100vh;
    .customer-info {
      padding-inline-start: 1rem;
    }
  }
  .remove-container {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.data-info {
  &.financialCard {
    border-bottom: unset;
  }
  &.noBg {
    border-bottom: unset;

    .cards-container {
      background: unset;
      border: unset;
    }
  }
  padding: 0 0 2rem;
  border-bottom: $border;
  .margin {
    margin-bottom: 1.5rem;
  }
  &.resource {
    padding-top: 0;
    .cards-container {
      width: fit-content;
    }
  }
  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
  }
  &.extraTopSpace {
    padding: 2rem 0 2rem;
  }
  &.grid {
    .cards-container {
      display: grid;
      grid-template-columns: auto auto auto auto;
      width: 100%;
    }
    &.financialCard {
      .cards-container {
        display: grid;
        grid-template-columns: auto auto auto auto auto; /* First column takes 1 fraction, rest take 3 */
        column-gap: 4rem;
      }
      .cards-container > *:first-child {
        grid-column: span 1; /* Span only the first column */
        grid-row: span 2; /* Make the first child take full height (you can adjust span for more rows if necessary) */
        height: 100%; /* Ensure the height is 100% */
        align-items: center;
        justify-content: center;
      }
    }
  }
  .cards-container {
    display: flex;
    align-items: center;
    height: fit-content;
    border-radius: 12px;
    background: #f1f1f1;
    border: $border;
    padding: 1rem 2rem;
    // width: 100%;
    width: fit-content;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 6rem;
    .card {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .logo {
        display: flex;
        align-items: center;
        gap: 1rem;
        .sp_status_container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .status {
            width: 5rem;
            height: 1.5rem;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            font-size: 0.8rem;

            &.inProgress {
              background-color: #f1c40f;
              width: 8rem;
            }
            &.pending {
              background-color: #f39c12;
            }
            &.accepted {
              background-color: rgb(46, 188, 46);
              cursor: unset;
            }
            &.rejected {
              background-color: red;
              cursor: unset;
            }
          }
        }
        img {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
        }
      }
      h6 {
        font-size: 0.8rem;
        opacity: 0.5;
      }
      .name {
        text-transform: capitalize;
        &.email {
          text-transform: none;
        }
      }
      .blue {
        // color: $blue-color;
      }
    }
  }
}

@media (max-width: 1024px) {
  .data-info {
    h2 {
      margin-bottom: 0;
    }
    &.grid {
      .cards-container {
        grid-template-columns: auto auto auto;
      }
      &.financialCard {
        .cards-container {
          grid-template-columns: auto auto auto auto;
          column-gap: 2rem;
        }

        .cards-container > *:first-child {
          grid-row: span 3;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .data-info {
    &.grid {
      .cards-container {
        grid-template-columns: auto auto;
      }
      &.financialCard {
        .cards-container {
          grid-template-columns: auto auto auto;
          column-gap: 2rem;
        }

        .cards-container > *:first-child {
          grid-row: span 4;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .data-info {
    &.grid {
      .cards-container {
        grid-template-columns: auto auto;
      }
      &.financialCard {
        .cards-container {
          grid-template-columns: auto auto;
          column-gap: 2rem;
        }

        .cards-container > *:first-child {
          grid-row: span 7;
          align-items: unset;
          justify-content: unset;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .data-info {
    &.grid {
      .cards-container {
        grid-template-columns: auto;
        text-align: center;
        justify-content: center;
      }
      &.financialCard {
        .cards-container {
          grid-template-columns: auto;
          column-gap: 2rem;
          padding: 2rem 2rem;
        }

        .cards-container > *:first-child {
          grid-row: span 4;
          align-items: unset;
          justify-content: unset;
        }
      }
    }
  }
}
