.comments-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;

  svg {
    // padding-top: 1px;
  }
}
.comments-side-header {
  color: #000;
}
.comment-category-title,
.comment-body,
.comment-author {
  color: #000;
}
.comment-body {
  word-wrap: break-word;
}
.comment-empty {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment-steps-btn {
  .button_container {
    padding: 0;
    height: fit-content;
    justify-content: flex-start;
  }
  .header-container {
    padding-block: 0em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: red;
    &.expand {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-radius: 0 !important;
    }
  }

  .comments-replies-arrow {
    svg {
      fill: white;
    }
  }
}
.comments-item,
.comments-replies-item {
  position: relative;
  padding-top: 1em;
  .header-container {
    padding-block: 0em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #000;
    color: #fff;
    svg {
      fill: white;
    }

    &.expand {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-radius: 0 !important;
    }
  }
  .comment-controls {
    display: flex;
    align-items: center;
    gap: 1em;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    .resolve_container{
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.5rem;
      text-decoration: underline;
      &:hover{
        opacity: 0.7;
      }

      &.resolved{
        opacity: 0.7;
        cursor: unset;
        text-decoration: none;
        svg {
          cursor: unset;
        }
      }
    }
    svg {
      cursor: pointer;
      width: 20px;
    }
    // .delete-comment{
    //   cursor: pointer;
    // }
  }
  .add-comment {
    position: relative;

    .icon-only {
      // background: red;
      z-index: 2;
      bottom: 4em;
      top: unset;
    }
  }
}

.comments-side {
  position: fixed;
  top: 0;
  right: 0;
  height: calc(100vh - 3rem);
  z-index: 10;
  transform: translateX(100%);
  padding: 1rem;
  width: 450px;
  max-width: 100%;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-block: 1.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0 1.5rem;
    flex-shrink: 0;
    h4 {
      font-size: 1rem;
    }
  }

  &-search {
    margin-bottom: 2rem;
    flex-shrink: 0;
  }

  &-categories {
    height: 100%;
    overflow: scroll;
    .comment-category {
      margin-bottom: 2.5rem;

      &-title {
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
      }

      .comments-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .comments-item {
        border: 1px solid rgba(0, 0, 0, 0.1);
        // padding: 1rem;
        border-radius: 8px;
        .comments-text {
          padding: 1.4rem 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          .comments-text-top {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.8rem;
            .comments-replies-date {
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.75rem;
              flex-shrink: 0;
              &::first-letter {
                text-transform: capitalize;
              }
            }
          }
          .comment-author {
            font-size: 1.1rem;
          }
          .comment-body {
            opacity: 0.8;
          }
        }

        .comments-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          user-select: none;
          width: 100%;
        }
        .comments-replies-arrow {
          transition: 0.3s;
          height: 17px;
          width: fit-content;
          transform: rotate(180deg);
        }
        .comments-replies-arrow-open {
          transform: rotate(0deg);
        }

        .MuiPaper-root {
          margin: 0;
          box-shadow: none;
          border-radius: 0 0 8px 8px;

          .MuiAccordionSummary-content {
            margin: 1rem 0;
          }
          .MuiButtonBase-root.Mui-expanded {
            min-height: unset !important;
          }

          &::before {
            display: none;
          }

          .MuiAccordionDetails-root {
            padding: 0;
          }

          .MuiButtonBase-root::after {
            display: none;
          }
        }

        .comments-replies {
          .comments-replies-list {
            padding: 0 1rem 1rem;
            .comments-replies-item {
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              background-color: #f6f6f6;
              .comments-text {
                border: none;
              }
              .comment-body {
                margin-bottom: 0;
              }

              &:not(:last-child) {
                margin-bottom: 1rem;
              }
            }

          }
          .load-more {
            text-align: center;
            padding: 0 1rem 1rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            svg{
              width: 1rem;
              height: 1rem;
            }
          }
          .load-more_btn{
            height: 2.5rem;
            min-width: 8rem;
            margin: 0 auto 1rem;

            .load-more_btn_content{
              display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            svg{
              width: 1rem;
              height: 1rem;
            }
            }
          }
        }
      }
      .comments-replies .add-reply,
      .add-comment {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        .field_wrapper_container {
          padding-bottom: 0;
          .error-icon {
            display: none !important;
          }
          input {
            padding-right: 3.2rem;
          }
        }
        .icon-only {
          padding-top: 0 !important;
        }
        .button_container {
          flex-shrink: 0;
          padding-top: 6px;
          // padding-right: 3px;
          width: 2.3rem;
          height: 2.3rem;
          position: absolute;
          right: 1.5rem;
          justify-content: center;
          .bold {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }

      .add-comment {
        padding: 0;
        padding-bottom: 2.5rem;
        border-top: unset;

        .button_container {
          right: 0.5rem;
        }
      }
    }
  }
}

.comments-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  .RemoveCommentAndReplayPopup {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 2em;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    z-index: 11;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    .RemoveCommentAndReplayPopup-container {
      background: white;
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 2em;
      border-radius: 12px;
      .RemoveCommentAndReplayPopup-btn {
        display: flex;
        gap: 1em;
        justify-content: center;
        button {
          &:nth-child(2) {
            background: transparent;
            span {
              color: #000 !important;
            }
          }
        }
      }
    }
  }
}

:lang(ar) {
  .comments-side {
    &-categories {
      .comment-category {
        .comments-replies .add-reply,
        .add-comment {
          .field_wrapper_container {
            input {
              padding-right: 1.5rem;
              padding-left: 3.2rem;
            }
          }
          .button_container {
            right: unset;
            left: 1.5rem;
          }
        }
        .add-comment {
          .button_container {
            right: unset;
            left: 0.5rem;
          }
        }
      }
    }
  }
  .comments-item,
  .comments-replies-item {
    position: relative;
    padding-top: 1em;
    .header-container {
      padding-block: 0em;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #000;
      color: #fff;
      svg {
        fill: white;
      }

      &.expand {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-radius: 0 !important;
      }
    }
    .comment-controls {
      display: flex;
      align-items: center;
      gap: 1em;
      position: absolute;
      left: 0.5em;
      right: unset;
      top: 0.5em;
      svg {
        cursor: pointer;
        width: 20px;
      }
      // .delete-comment{
      //   cursor: pointer;
      // }
    }
    .add-comment {
      position: relative;

      .icon-only {
        // background: red;
        z-index: 2;
        bottom: 4em;
        top: unset;
      }
    }
  }
}

@media (max-width: 1024px) {
  .comments-side {
    margin-block: 1rem;
    height: calc(100vh - 2rem);
  }
}
@media (max-width: 400px) {
  .comments-side {
    border-radius: 0;
  }
}

.comment-skeleton {
  // height: 40vh;
  padding: 1.4rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .comment-skeleton-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .skeleton_title {
      height: 10px;
    }
    & > span {
      width: 25%;
      &:nth-child(1) {
        width: 40%;
      }
    }
  }
  .comment-skeleton-desc {
    height: 20px;
    .skeleton_title {
      height: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .comments-side {
    width: 100%;
  }
}
