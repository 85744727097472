.filter-menu {
    position: fixed;
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
    border-radius: $border_radius;

    &.open {
        width: 100%;
    }

    .comments-side {
        width: 20rem;
        transform: translateX(100%);
        transition: .2s all;
        max-height: 100%;
        overflow-y: scroll;
        padding: 0;

        &-header {
            margin-bottom: .5rem;
            position: sticky;
            background-color: #fff;
            top: 0;
            z-index: 2;
            padding: 1rem;
        }
        &-categories{
            padding: 0 1rem 1rem;
        }

        &.open {
            transform: translateX(0);
        }

        .range-header {
            font-size: .85rem;
        }

        .MuiSlider-rail {
            color: #000;
        }

        .form_button {
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .button_container {
                min-width: 7rem;
                height: 2.5rem;

                span {
                    font-size: .85rem;
                }
            }

            .clear {
                font-size: .8rem;
                color: #2294e0;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1024px) {
    .filter-menu {
        .comments-side {
            width: 100%;
        }
    }
}