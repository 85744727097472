$padding_inline: 1.5rem;
$padding_inline_mobile: 1rem;
$padding_inline_inputs: 1.5rem;
// $border_radius: 1.25rem;
$btns_border_radius: 2.25rem;
$border_radius: 12px;
// $btns_border_radius: 12px;
$main_bg: #000;
$gray_bg: #f5f5f5;
// $sky_blue: #2a6cce;
$sky_blue_bg: #cedee7;
$border: solid 1px #e5e5e5;
$green_color: #009739;
$modal_bg: rgba(0, 0, 0, 0.6);
$teams_modal_height: 85vh;
$red: #ef3340;
$error_bg: #f8d7da;
$success_bg: #d4edda;
