.dashboard-data {
    display: flex;
    gap: 1rem;
    border-bottom: $border;

    &.no-border {
        border: none;
        margin-bottom: -1rem;
    }

    &.vehicles {
        padding-bottom: 1rem;
    }

    &:last-of-type {
        border: none;
    }

    &.no-dashboard-data {
        .charts {
            .common-highlight {
                .cards-container {

                    grid-template-columns: repeat(5, 1fr);
                }
            }
        }
    }



    .charts {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &.row {
            flex-direction: row;
            flex: 1;

        }

        .common-highlight {
            .cards-container {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        flex: 1;
    }

    .top-dashboard-data {
        flex: .35;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;

    }

}

.dashboard-header {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem
}


@media (max-width:1024px) {
    .dashboard-date-range {
        .rdrCalendarWrapper {
            width: 75vw;
            overflow: auto;

            .rdrDateDisplayWrapper {
                width: 85vw;
            }
        }
    }

    .dashboard-header {
        width: auto;
    }

    .dashboard-data {
        flex-direction: column;

        .charts {
            &.row {
                flex-direction: column;
                flex: 1;

            }

            .common-highlight {
                .cards-container {
                    grid-template-columns: repeat(1, 1fr);

                    .card {
                        height: unset;

                        .v1 {
                            left: 6rem;

                            &.v2 {
                                left: unset;
                                right: 5rem;
                            }
                        }

                        p {
                            text-align: center;
                            margin-top: .5rem;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

}

.chart-tooltip {
    background-color: red !important;
    color: #333 !important;
    padding: 10px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}