.breadcrumbs {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;

    .link {
      text-transform: capitalize;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      color: #000;
      cursor: pointer;

      &.dimmed {
        color: rgba($color: #000, $alpha: 0.7);
        cursor: unset;
      }

      &:last-child {
        .chevron {
          display: none;
        }
      }
    }
  }

  .titles {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .page_title {
      font-size: 1.25rem;
    }

    .status {
      width: 5rem;
      height: 1.5rem;
      background-color: red;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0.8rem;

      &.loading {
        opacity: 0.5;
        background-color: #908e94;
      }

      &.active {
        background-color: rgb(46, 188, 46);
      }

      &.inProgress {
        background-color: #f1c40f;
      }

      &.rejected {
        background-color: red;
      }
    }

    .toggle {
      .loader {
        margin-inline-start: 1rem;
        width: 1.25rem;
        height: 1.25rem;
        opacity: 0.75;
      }

      &.checked {
        .MuiSwitch-root {
          .MuiSwitch-track {
            background-color: rgb(46, 188, 46) !important;
          }
        }
      }

      .MuiSwitch-root {
        .MuiSwitch-track {
          background-color: red !important;
          opacity: 0.7;
        }

        .MuiSwitch-switchBase {
          color: red;
          /* Default color for the switch */

          &.Mui-checked {
            color: rgb(46, 188, 46);
            /* Color for the switch when checked */
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .breadcrumbs {
    .links {
      display: none;
    }

    .titles {
      // flex-direction: column;
      align-items: flex-start;
      // gap: 0;
      // gap: 1rem;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 1rem;
  height: 1rem;
  border: 1px solid #ede7f6;
  border-top: 2px solid #000;
  border-radius: 50%;
  animation: lds-dual-ring 2s linear infinite;
}
