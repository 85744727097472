.drag-image-container {
  width: 100%;
  height: 10rem;
  background: rgba(33, 29, 51, 0.03);
  border: 1px dashed rgba(33, 29, 51, 0.3);
  border-radius: 12px;
  gap: 0.25rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.15s all;
  &.disabled {
    opacity: 0.5;
  }
  &.normal-cursor {
    cursor: unset;
  }

  &.hasFile {
    cursor: unset;
  }
  .drag-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem;

    .uploadIcon {
      position: absolute;
      top: 1rem;
      right: 2rem;
      cursor: pointer;
      svg:hover ~ .tooltip-box {
        display: flex;
        animation: fade 0.5s;
      }
      svg {
        width: 1.7rem;
        height: 1.7rem;
      }
      .tooltip-box {
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        right: -1.6rem;
        padding: 0.5rem 0.75rem;
        top: 3.4rem;
        transform: translateY(-50%);
        color: #000;
        flex-direction: column;
        display: none;
        z-index: 7;
        border: solid 1px #e5e5e5;
        gap: 1rem;
        box-shadow:
          0 3px 6px -4px rgba(0, 0, 0, 0.1),
          0 6px 16px rgba(0, 0, 0, 0.1),
          0 9px 28px 8px rgba(0, 0, 0, 0.1);

        .desc {
          text-transform: none;
          font-size: 0.7rem;

          ul {
            list-style-type: disc;
            padding-inline-start: 1rem;
          }
        }

        &::after {
          content: '';

          position: absolute;
          top: -1rem;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
          border: 0.5rem solid transparent;
          border-top-color: #fff;
        }
      }
    }
    .fileIcon {
      svg {
        width: 4rem;
        height: 4rem;
      }
    }

    .fileName {
      width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.drag-error-container {
    border: 1px dashed red;
    background-color: #f7e7e9;
  }
  .types {
    opacity: 0.4;
  }
  &.images-added {
    border: none;
    background-color: transparent;
    cursor: unset;
    height: unset;
    &.build-form {
      position: relative;
      background: rgba(33, 29, 51, 0.03);
      border: 1px dashed rgba(33, 29, 51, 0.3);
      height: 14rem;
      .images-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-container {
          position: relative;
          width: 80%;
          height: 80%;
          overflow: hidden;
          border: unset;
          img {
            object-fit: contain;
          }
        }
      }
      .edit-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
      }
    }
    .drag-wrapper {
      align-items: flex-start;
      margin-top: 1rem;
      width: fit-content;
    }
    .images-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      .img-container {
        border: 1px dashed rgba(33, 29, 51, 0.3);
        background: rgba(33, 29, 51, 0.03);
        border-radius: 12px;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .img-container-with-title {
          display: flex;
          gap: 1.5rem;
          align-items: center;
        }
        img {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
          object-fit: contain;
        }

        div {
          cursor: pointer;
        }
      }
    }
  }
}
.drag-error {
  position: absolute;
  bottom: 0.75rem;
  left: 1rem;
  color: red;
  z-index: 2;
  font-size: 0.85rem;
  &.builder {
    bottom: 1rem;
  }
}
