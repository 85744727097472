.error-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: $error_bg;
  margin: 0;
  // position: absolute;
  h4 {
    font-size: 1.25rem;
  }
  .paragraph-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    a {
      text-decoration: underline;
    }
  }
}
