.top-data {
    background-color: #fff;
    border-radius: $border_radius;
    padding: 1rem;

    h4 {
        font-size: 0.8rem;
        opacity: 0.5;
        margin-bottom: 1rem;
    }

    .card {
        display: flex;
        justify-content: space-between;
        border-bottom: $border;
        padding: .5rem 0;

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }

        h6 {
            font-size: 0.75rem;
        }

        p {
            font-size: .75rem;
        }
    }

    &.provider {
        padding-inline: 0;
        padding-bottom: 0;
        overflow: hidden;

        h4 {
            padding-inline: 1rem;
        }

        .card {
            cursor: pointer;
            padding-inline: 1rem;

            &:last-of-type {
                padding-bottom: .5rem;
            }

            &.active {
                background-color: rgba(0, 0, 0, 0.05);
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }

    }
}