.admin_panel_header {
  // z-index: 10;
  border-bottom: solid 1px rgba($color: #000, $alpha: 0.1);
  position: relative;
  padding-bottom: $padding_inline;
  position: sticky;
  top: -0.1px;
  border-radius: 0;
  background-color: $gray_bg;
  z-index: 2;
  padding-top: 1.5rem;

  .auth_page_header {
    .logo_container {
      // width: 8rem;
      position: relative;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .normal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3.1rem;

    .icon_container {
      cursor: pointer;

      svg {
        transition: transform 0.3s ease-in-out;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .left-side {
      display: flex;
      gap: 1rem;
      align-items: center;

      .admin_btn {
        position: relative;
        .icon_container {
          display: flex;
          align-items: center;
          // gap: 0.25rem;
          background: #fff;
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: rgba(105, 107, 113, 0.22) 0px 2px 10px 0px;
          position: relative;
        }

        .dropdown {
          display: none;
          position: absolute;
          background-color: #fff;
          box-shadow:
            0 3px 6px -4px rgba(0, 0, 0, 0.1),
            0 6px 16px rgba(0, 0, 0, 0.1),
            0 9px 28px 8px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          flex-direction: column;
          top: calc(100% + 1rem);
          right: -0.5rem;
          min-width: 13rem;
          padding: 1rem;
          z-index: -1;
          opacity: 0;
          transform: opacity 0.3s ease-in-out;

          &.show {
            display: flex;
            opacity: 1;
            z-index: 2;
          }

          &::after {
            content: '';
            position: absolute;
            top: -1rem;
            right: 1.25rem;
            border: 0.5rem solid transparent;
            border-bottom-color: #fff;
            z-index: 2;
          }

          .drop-header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-bottom: $border;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            text-transform: capitalize;

            .header {
              h6 {
                font-weight: 500;
              }
            }

            &.titles {
              text-transform: unset;
              flex-direction: column;
              align-items: flex-start;

              p {
                opacity: 0.5;
                font-size: 0.8rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }

              span {
                cursor: pointer;
                padding-bottom: 0.25rem;
                transition: 0.25s all;

                &:hover {
                  color: #000;
                }
              }

              a {
                font-size: 0.9rem;
              }
            }
          }
        }
      }

      .admin_data_btn {
        .icon_container {
          .active_icon {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background: $green_color;
            border-radius: 50%;
            top: 80%;
            right: 0;
            transform: translate(-0.25rem, 0);
          }
        }
        .log-out {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.25s all;
          font-size: 0.9rem;

          svg {
            transition: 0.25s all;
          }

          &:hover {
            color: #000;

            svg {
              path {
                fill: #000;
              }
            }
          }
        }
      }

      .admin_notifications_btn {
        .icon_container {
          .count_number {
            background-color: red;
            border-radius: 50%;
            width: 1.3rem;
            height: 1.3rem;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.7rem;
            color: white;
          }
        }
        .admin_notifications_drop_down {
          min-width: unset;
          width: 26rem;
          padding: 0;
          .drop-header {
            padding: 1rem;
            margin-bottom: 0;
            // padding-inline-end: .75rem;
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              h6 {
                font-weight: bold;
              }
              a {
                font-size: 0.9rem;
                font-weight: normal;
                cursor: pointer;
                position: relative;
                &::after{
                  content: "";
                  position: absolute;
                  top: calc(100% + 4px);
                  left: 0;
                  width: 0;
                  height: 1px;
                  background-color: black;
                  transition: width .2s;
                }
                &:hover::after{
                  border-color: black;
                  width: 100%;
                }
              }
            }
          }
          .notifications {
            display: flex;
            flex-direction: column;
            align-items: start;
            max-height: 18rem;
            overflow: scroll;
            border-radius: 0 0 8px 8px;
            .notifications-item {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              width: 100%;
              padding: 1rem;
              cursor: pointer;
              transition: 0.2s;

              .notifications-item-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 0.5rem;
                height: 1em;
              }
              .notifications-date {
                color: rgba(0, 0, 0, 0.7);
                font-size: 0.8rem;
                // width: 100%;
                text-align: end;
              }
              .notifications-content {
                display: flex;
                gap: 0.5rem;
                line-height: 1.5;
                span {
                  padding-top: 0.5rem;
                }
                .notification-message {
                  font-size: 0.85rem;
                  padding: 0;
                }
              }
              .notifications-dot {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: $red;
                &-container {
                  width: 0.5rem;
                }
              }

              &.read,
              &:hover {
                background-color: rgb(244, 244, 244);
              }

              &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 1rem;
              }
            }
          }
          .show-all {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            cursor: pointer;
            .arrow-icon {
              padding-top: 1px;
            }
          }
        }
      }

      .burger {
        display: none;
      }

      .hz-line {
        display: flex;
        height: 2rem;
        width: 2px;
        background-color: rgba($color: #000, $alpha: 0.5);
        margin: auto;
      }

      .lang {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .lang-name {
        display: flex;
      }
    }
  }
  &.login_page_header {
    padding-top: 0;
  }
}

:lang(ar) {
  .admin_panel_header {
    .normal_header {
      .left-side {
        .admin_btn {
          .dropdown {
            right: unset;
            left: -0.5rem;

            &::after {
              right: unset;
              left: 1.25rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .burger {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem;
    background: #fff;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-shadow: rgba(105, 107, 113, 0.22) 0px 2px 10px 0px;
    position: relative;
    padding-inline: 0.75rem;
    cursor: pointer;

    span {
      border: 1px solid rgba($color: #000, $alpha: 0.5);
      width: 100%;

      &:nth-child(2n) {
        width: 40%;
      }

      &:nth-child(3n) {
        width: 70%;
      }
    }
  }

  .admin_panel_header {
    .normal_header {
      .left-side {
        .lang-name {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .admin_panel_header {
    .normal_header {
      // flex-direction: column-reverse;
      // gap: 1rem;
      // align-items: flex-start;
      align-items: flex-start;
      .breadcrumbs {
        .titles {
          flex-direction: column;
          gap: 1rem;
          .page_title {
            font-size: 1.1rem;
          }
          svg {
            width: 1.6rem;
            height: 1.5rem;
            margin-right: 0.4rem !important;
          }
        }
      }
      .left-side {
        width: 100%;
        justify-content: flex-end;
        .hz-line,
        .lang {
          display: none;
        }

        .admin_notifications_btn {
          position: static;
          .icon_container {
            .count_number {
              background-color: red;
              border-radius: 50%;
              width: 1.3rem;
              height: 1.3rem;
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.7rem;
              color: white;
            }
          }
          .admin_notifications_drop_down {
            min-width: unset;
            right: 0;
            top: 5em;
            width: 100%;
            padding: 0;
            &::after {
              right: 4.2rem;
            }
            .notifications {
              max-height: 23em;
            }
          }
        }
      }
    }
  }
}
