.fields_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  .file {
    padding-bottom: 2.5rem;
  }
}

@media (max-width: 900px) {
  .fields_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
@media (max-width: 750px) {
  .fields_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
