.inputs_group {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 3rem;
	// padding-bottom: 1rem;

	.field_wrapper_container {
		flex: 1;
	}

	&:last-of-type {
		.field_wrapper_container {
			// padding-bottom: 0;
		}
	}
}

@media (max-width: 1024px) {
	.inputs_group {
		flex-direction: column;
		gap: 0;

		&.special-mobile {
			.button_container {
				width: 100%;
			}
		}
	}
}