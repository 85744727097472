.contacts-cards-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;

  .contact-info-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    background: #fff;
    border: solid 1px #e5e5e5;
    padding: 1rem 2rem;
    position: relative;
    .contact-actions {
      position: absolute;
      right: 1rem;
      top: 1rem;
      .action {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        cursor: pointer;
      }
    }
    .contact-info-card-container {
      text-align: start;
      .name {
        font-size: 1.1rem;
        font-weight: 600;
      }
      .profession {
        font-size: 0.9rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }
      .email {
      }
      .mobile {
        padding-bottom: 1rem;
      }

      .action {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        .action_btn {
          cursor: pointer;

          svg:not([fill='none']) {
            path {
              fill: rgba($color: #000000, $alpha: 0.85);
            }
          }

          &:hover {
            svg:not([fill='none']) {
              path {
                fill: #000;
              }
            }
          }
        }
      }
    }
  }
}



@media (max-width: 1024px) {
    .contacts-cards-section {
        grid-template-columns: 1fr 1fr;

    }
}
@media (max-width: 800px) {
    .contacts-cards-section {
        grid-template-columns: 1fr;

    }
}