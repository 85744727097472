.customer-info {
	form {
		.customer-info-container {
			display: flex;
			gap: 2vw;

			.button-container {
				margin-bottom: 2rem;
			}

			.form-wrapper {
				flex: 1;
			}

			.products {
				flex: .75;
			}
		}
	}
}

@media (max-width: 1024px) {
	.customer-info {
		form {
			.customer-info-container {
				flex-direction: column;
				gap: 1.5rem;

				.products {
					padding-bottom: 3.5rem;
				}
			}
		}
	}
}
