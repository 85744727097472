.duration-tabs {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto; 
    
    li {
        padding: 0.75rem 0;
        font-size: 0.75rem;
        width: 7rem;
        text-align: center;
        border: $border;
        border-radius: $border_radius;
        cursor: pointer;
        transition: 0.25s all;
        flex-shrink: 0;
        
        &.active {
            background: #000;
            color: #fff;
        }
    }
}

@media (max-width: 1024px) {
    .duration-tabs {
        max-width: 100vw;
    }
}
