.search-results {
	position: relative;

	.last {
		margin-bottom: 0;
	}

	&.customer {
		padding: 1rem;
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 12px;

		.products-container {
			width: 100%;

			.product-card {
				.service-data {
					span {
						font-size: 0.85rem;
					}
				}

				.price-data {
					flex: 1.25;

					.offer {
						span {
							font-size: 0.75rem;
						}
					}

					div {
						p {
							font-size: 0.75rem;
						}

						span {
							font-size: 0.85rem;
						}
					}
				}
			}
		}
	}

	.products-container {
		position: relative;

		.product-card {
			cursor: pointer;
			border: solid 1px rgba(0, 0, 0, 0.1);
			border-radius: 12px;
			margin-bottom: 2rem;
			background-color: #fff;
			transition: 0.75s all;
			display: flex;
			position: relative;

			&.normal-cursor {
				opacity: 0.5;

				.check {
					display: none;
				}
			}

			.icon {
				position: absolute;
				top: 0rem;
				right: 0rem;
			}

			.check {
				position: absolute;
				top: 1rem;
				left: 1rem;

				input {
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
				}

				input[type="checkbox"] {
					accent-color: #000;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
			}

			.service-data {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 1rem;

				.logo-container {
					display: flex;
					width: 4rem;
					height: 4rem;
					border-radius: 50%;
					// background-color: #000;
					justify-content: center;

					img {
						object-fit: contain;
					}
				}
			}

			section {
				padding: 2rem;
				flex: 1;
				// border-inline-end: solid 1px rgba(0, 0, 0, 0.1);

				.logo-container {
					display: flex;
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					// background-color: #000;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			&:last-child {
				border: none;
			}

			.drag-icon {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: absolute;
				top: 0.5rem;
				left: 0;

				span {
					padding-inline: 1rem;
				}

				.dragIcon {
					width: 1.5rem;
					height: 1.5rem;
				}
			}

			&.drag {
				flex-direction: column;

				.price-data {
					display: flex;
					position: relative;
					gap: 0;
					flex-direction: column;

					.data {
						flex-direction: row;
						display: flex;
						justify-content: space-between;
					}

					.offer {
						height: 100%;
						width: 100%;
						position: relative;
						right: unset;
						padding-top: 1.5rem;
						display: flex;
						justify-content: center;
						align-items: center;

						span {
							transform: rotate(0deg);
							opacity: 0.5;
							font-size: 0.75rem;
						}
					}
				}

				.service-data {
					padding: 0.5rem 1rem 1rem;
				}

				section {
					padding: 2rem 1rem;
					border-bottom: solid 1px rgba(0, 0, 0, 0.1);

					&:last-child {
						border: none;
					}
				}
			}
		}

		.basic-data {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1.5rem;

			div {
				display: flex;
				flex-direction: column;
				gap: 0.25rem;

				&:nth-child(even) {
					text-align: end;
				}

				p {
					font-size: 0.75rem;
					opacity: 0.7;
					text-transform: capitalize;
				}

				span {
					font-size: 0.85rem;

					&.old {
						text-decoration: line-through;
						opacity: 0.5;
					}
				}
			}
		}

		.price-data {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			position: relative;

			.offer {
				height: 100%;
				width: 100%;
				position: absolute;
				right: 0rem;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: flex-end;

				span {
					transform: rotate(270deg);
					opacity: 0.5;
					font-size: 0.75rem;
				}
			}

			div {
				display: flex;
				flex-direction: column;
				gap: 0.25rem;

				p {
					font-size: 0.9rem;
					opacity: 0.7;
					text-transform: capitalize;
				}

				span {
					font-size: 1rem;
					text-transform: capitalize;

					&.old {
						text-decoration: line-through;
						opacity: 0.5;
					}
				}
			}
		}
	}

	.no-data {
		width: 100%;
		min-height: 60vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 1rem;

		p {
			font-size: 2rem;
		}

		span {
			&.link {
				color: rgb(0, 132, 255);
				cursor: pointer;
			}
		}
	}
}

@media (max-width: 1024px) {
	.search-results {
		.products-container {
			width: 100%;

			.product-card {
				flex-direction: column;

				.price-data {
					display: flex;
					flex-direction: column;
					position: relative;

					.offer {
						height: 100%;
						width: 100%;
						position: relative;
						right: unset;
						padding-top: 1.5rem;
						display: flex;
						justify-content: center;
						align-items: center;

						span {
							transform: rotate(0deg);
							opacity: 0.5;
							font-size: 0.75rem;
						}
					}
				}

				section {
					padding: 2rem 1rem;
					border-bottom: solid 1px rgba(0, 0, 0, 0.1);

					&:last-child {
						border: none;
					}
				}
			}
		}
	}
}