.button_container {
	background: #000;
	padding: 1.2rem 2rem;
	height: 3rem;
	border-radius: $btns_border_radius;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	flex-direction: row-reverse;
	// border: 1px solid $blue-color;

	&.icon-only{
		border-radius: 50%;
		height: 3rem;
		width: 3rem;
		min-width: unset;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.red {
		background-color: rgb(161, 28, 28);
		border: 1px solid rgb(161, 28, 28);
	}

	&.terminated-buttons {
		padding: 0;
		width: 12rem;
		height: 3rem;
	}

	&.login-button {
		padding: 0;
		width: 8rem;
		height: 3rem;

		&.change {
			width: 12rem;
		}
	}

	&.loading {
		opacity: 0.5;
		cursor: unset;
	}

	&.disabled {
		opacity: 0.5;
		cursor: unset;
	}

	&.prev {
		background: #000;
		border: 1px solid #000;
	}

	&.no-bg {
		background: transparent;
		border: $border;

		span {
			color: #000;
		}
	}

	span,
	a {
		color: #fff;
	}

	&.white {
		background: #fff;
		border: $border;

		span {
			color: #000;
		}

		&.disable {
			cursor: unset;
		}
	}
}

@media (min-width: 1024px) {
	.button_container {
		min-width: 9.65rem;
		font-size: 0.8rem;
		span,
		a {
			font-size: 0.8rem;
			text-transform: capitalize;
		}
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.submit_loading_container {
	width: 1.25rem;
	height: 1.25rem;
	border: 2px solid #ede7f6;
	border-top: 2px solid #000;
	border-radius: 50%;
	animation: lds-dual-ring 2s linear infinite;
}
