.create_product_container {
	border: $border;
	border-radius: $border_radius;
	// overflow: hidden;
	min-height: 100%;
	height: 100%;
	position: relative;

	.creation_step {
		padding: 2.5rem 1.5rem 1.5rem;
	}
}

@media (max-width: 1024px) {
	.create_product_container {
		border: none;
		.creation_step {
			// padding: 0;
		}
	}
}
