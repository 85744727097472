@font-face {
	font-family: "PlusJakartaSans-Light";
	src: url("../../public/fonts/PlusJakartaSans-Light.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "PlusJakartaSans-Medium";
	src: url("../../public/fonts/PlusJakartaSans-Medium.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "PlusJakartaSans-Bold";
	src: url("../../public/fonts/PlusJakartaSans-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

:root {
	--saans_trial_regular: "PlusJakartaSans-Light", sans-serif;
	--saans_trial_medium: "PlusJakartaSans-Medium", sans-serif;
	--saans_trial_bold: "PlusJakartaSans-Bold", sans-serif;
}
