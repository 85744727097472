.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    .card {
        background-color: #ffffff;
        border-radius: 0.5rem;
        box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
        padding: 2rem;
        max-width: 28rem;

        width: 100%;
        h1 {
            font-size: 1rem;
            line-height: 2rem;
            text-align: center;
            text-transform: capitalize;
            }
    }
}